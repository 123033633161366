.text-headline-2xl,
.text-quote-lg,
.text-quote-sm {
  @apply italic;
}

.text-gabor-xl,
.text-gabor-lg,
.text-gabor-md,
.text-gabor-sm,
.text-quote-lg,
.text-quote-sm,
.text-tag-md,
.text-button-md {
  @apply uppercase;
}

.bg-el-skogsgron-900 {
  @apply bg-forest-green-900;
}

.sm\:bg-el-skogsgron-900 {
  @apply sm:bg-forest-green-900;
}

.wings-el-skogsgron-900 {
  @apply wings-forest-green-900;
}

.sm\:wings-el-skogsgron-900 {
  @apply sm:wings-forest-green-900;
}

.bg-el-lejongul-900 {
  @apply bg-lion-yellow-900;
}

.sm\:bg-el-lejongul-900 {
  @apply sm:bg-lion-yellow-900;
}

.wings-el-lejongul-900 {
  @apply wings-lion-yellow-900;
}

.sm\:wings-el-lejongul-900 {
  @apply sm:wings-lion-yellow-900;
}

.bg-el-varmrod-900 {
  @apply bg-warm-red-900;
}

.sm\:bg-el-varmrod-900 {
  @apply sm:bg-warm-red-900;
}

.wings-el-varmrod-900 {
  @apply wings-warm-red-900;
}

.sm\:wings-el-varmrod-900 {
  @apply sm:wings-warm-red-900;
}

.bg-el-mjuklila-900 {
  @apply bg-soft-purple-900;
}

.sm\:bg-el-mjuklila-900 {
  @apply sm:bg-soft-purple-900;
}

.wings-el-mjuklila-900 {
  @apply wings-soft-purple-900;
}

.sm\:wings-el-mjuklila-900 {
  @apply sm:wings-soft-purple-900;
}

.bg-el-aprikos-orange-900 {
  @apply bg-apricot-orange-900;
}

.sm\:bg-el-aprikos-orange-900 {
  @apply sm:bg-apricot-orange-900;
}

.wings-el-aprikos-orange-900 {
  @apply wings-apricot-orange-900;
}

.sm\:wings-el-aprikos-orange-900 {
  @apply sm:wings-apricot-orange-900;
}

.bg-el-mjukrosa-900 {
  @apply bg-soft-pink-900;
}

.sm\:bg-el-mjukrosa-900 {
  @apply sm:bg-soft-pink-900;
}

.wings-el-mjukrosa-900 {
  @apply wings-soft-pink-900;
}

.sm\:wings-el-mjukrosa-900 {
  @apply sm:wings-soft-pink-900;
}

.bg-el-light-sand-900 {
  @apply bg-light-sand-900;
}

.sm\:bg-el-light-sand-900 {
  @apply sm:bg-light-sand-900;
}

.wings-el-light-sand-900 {
  @apply wings-light-sand-900;
}

.sm\:wings-el-light-sand-900 {
  @apply sm:wings-light-sand-900;
}

.bg-el-yves-klein-900 {
  @apply bg-yves-klein-900;
}

.sm\:bg-el-yves-klein-900 {
  @apply sm:bg-yves-klein-900;
}

.wings-el-yves-klein-900 {
  @apply wings-yves-klein-900;
}

.sm\:wings-el-yves-klein-900 {
  @apply sm:wings-yves-klein-900;
}

.text-tag {
  @apply text-gray-200;
}

.sm\:text-tag {
  @apply sm:text-gray-200;
}

.font-el-light-sand-900 {
  @apply text-light-sand-900;
}

.sm\:font-el-light-sand-900 {
  @apply sm:text-light-sand-900;
}

.font-el-yves-klein-900 {
  @apply text-yves-klein-900;
}

.sm\:font-el-yves-klein-900 {
  @apply sm:text-yves-klein-900;
}
